<template>
	<div class="about">
		<section class="about--text-marquee" v-if="Object.keys(fields).length">
			<marquee-text v-for="(fld, index) in fields.testo_scorrevole" :key="index" :repeat="parseInt(fld.ripetizioni)" :reverse="fld.inverso" :duration="parseInt(fld.durata)">{{fld.testo}}</marquee-text>

			<div class="about--image">
				<img :src="fields.immagine" alt="">
			</div>

			<div class="bottom--title">
				<span>{{fields.titolo_about_1}}</span>
			</div>
		</section>
		<section class="about--timeline" v-if="Object.keys(fields).length">
			<div class="time--block" v-for="(block, index) in fields.timeline" :key="index">
				<h3>{{block.data}}</h3>
				<p>{{block.testo}}</p>
			</div>
			<div class="about--image2">
				<img :src="fields.immagine_2" alt="">
			</div>
		</section>
		<section class="about--text-description" v-if="Object.keys(fields).length">
			<div class="text--wrapper">
				<h3>
					<span v-for="(text, index) in fields.testo_about_1.split(/\r?\n/)" :key="index">{{text}}</span>
				</h3>
				<p>{{fields.testo_about_2}}</p>
			</div>
		</section>		
		<section class="about--next-page" v-if="Object.keys(fields).length">
			<div class="next--wrapper">
				<router-link :to="$i18nRoute({ name: 'Applications' })">
					<div class="title">
						<h3 v-for="(title, index) in $t('about.next.titolo').split('#')" :key="index" >
							{{title}}
						</h3>
					</div>
					<div class="image">
						<img :src="fields.next_page.immagine">
					</div>
				</router-link>
			</div>
		</section>
	</div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component'
import axios from 'axios'
export default {
	data() {
		return {
			fields: {},
			next: [],
			page: parseInt(this.$t('about.page_id')),
			//nextPage: parseInt(this.$t('sostenibilita.page_id'))
		}
	},
	metaInfo() {
		return{
			title: this.$t('head.title.about'),
			titleTemplate: '%s | Wearlight',
		}
  },
	components: {
		MarqueeText
	},
	watch: {
		'$route.params.locale': function(){
			this.page = parseInt(this.$t('about.page_id'))
			//this.nextPage = parseInt(this.$t('sostenibilita.page_id'))
			this.getAbout()
			//this.getNext()
		}
  },
	methods: {
		getAbout(){
			axios
				.get(process.env.VUE_APP_API_URL + '/wl/v1/acf/getfieldbyid/' + this.page)
				.then(response => {
					this.fields = response.data
				})
		}
	},
	beforeMount() {
		this.getAbout()
	}
}
</script>

<style lang="scss">
.about {
	position: relative;
	width: 100%;
	padding-top: 10vh;
	.about--text-marquee {
		position: relative;
		width: 100%;
		.marquee-text-wrap {
			.marquee-text-content {
				width: 100%;
				display: flex;
				flex-wrap: nowrap;
				.marquee-text-text {
					font-family: 'Suisse Int';
					font-weight: 500;
					color: $black;
					font-size: 10vw;
					line-height: 10vw;
					text-transform: uppercase;
					@include mobile {
						font-size: 20vw;
						line-height: 20vw;
					}
				}
			}
			&:first-of-type {
				.marquee-text-content {
					.marquee-text-text {
						padding-right: 5vw;
						z-index: 76;
					}
				}
			}
			&:nth-of-type(2) {
				.marquee-text-content {
					.marquee-text-text {
						padding-right: 25vw;
						z-index: 76;
					}
				}
			}
			&:nth-of-type(3) {
				.marquee-text-content {
					.marquee-text-text {
						padding-right: 100vw;
						z-index: 74;
					}
				}
			}
			&:nth-of-type(4) {
				.marquee-text-content {
					.marquee-text-text {
						padding-right: 121vw;
						z-index: 76;
					}
				}
			}
		}
		.about--image {
			position: absolute;
			left: 50%;
			transform: translate(-50%, -15vw);
			width: 90% ; 
			height: auto; 
			z-index: 75; 
			img {
				width: 100%;
				height: auto;
				display: block;
			}
			@include mobile { 
				width: 80%;
				top: 40vw;
				transform: translate(-50%, 0);
			}
		}
		.bottom--title {
			position: relative;
			z-index: 76;
			margin-top: 13vw;
			margin-left: 15vw;
			display: none;
			visibility: hidden;
			span {
				font-family: 'Suisse Int';
				font-weight: 500;
				color: $black;
				font-size: 10vw;
				line-height: 10vw;
				text-transform: uppercase;
			}
			@include mobile {
				margin-top: 25vw;
			}
		}
	}
	.about--text-description {
		position: relative;
		width: 100%;
		padding: 20vh 0;
		display: flex;
		justify-content: center;
		align-items: center;
		@include mobile {
			padding: 5vh 0;
		}
		.text--wrapper {
			position: relative;
			width: 50%;
			height: auto;
			display: flex;
			flex-direction: column;
			@include mobile {
				width: 100%;
				padding: 0 5vw;
			}
			h3 {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin: 0 0 20vh 0;
				span {
					text-transform: uppercase;
					font-family: 'Saol Display';
					font-size: 4vw;
					line-height: 4vw;
					font-weight: 400;
					color: $black;
					@include mobile {
						font-size: 8vw;
						line-height: 8vw;
					}
				}
				@include mobile {
					margin-bottom: 5vh
				}
			}
			p {
				color: $black;
				font-size: 24px;
				font-weight: 400;
				font-family: 'Suisse Int';
				width: 70%;
				align-self: flex-end;
				@include mobile {
					width: 100%;
					font-size: 16px;
					line-height: 20px;
				}
			}
		}
	}
	.about--timeline {
		position: relative;
		width: 100%;
		padding: calc(20vh + (0.57*90vw) - 15vw) 10vw 0 10vw;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		box-sizing: border-box;
		@include mobile {
			padding: 15vh 0 10vh 0;
		}
		.time--block {
			position: relative;
			width: 40vw;
			height: auto;
			margin-bottom: 10vh;
			display: flex;
			flex-direction: column;
			@include mobile {
				width: 90vw;
			}
			&:nth-of-type(odd){
				align-self: flex-start;
				text-align: right;
				justify-content: flex-end;
				align-items: flex-end;
			}
			&:nth-of-type(even){
				align-self: flex-end;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
			h3 {
				font-family: 'Suisse Int';
				font-weight: 500;
				color: $black;
				font-size: 10vw;
				line-height: 10vw;
				text-transform: uppercase;
				margin: 0;
				@include mobile {
					font-size: 20vw;
					line-height: 20vw;
				}
			}
			p {
				color: $black;
				font-size: 24px;
				font-weight: 400;
				font-family: 'Suisse Int';
				width: 70%;
				@include mobile {
					width: 100%;
					font-size: 16px;
					line-height: 20px;
				}
			}
		}
		.about--image2 {
			position: relative;
			width: 40vw;
			height: auto;
			@include mobile {
				width: 80vw;
			}
			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}
	}
	.about--next-page {
		position: relative;
		width: 100%;
		height: 100vh;
		background-color: $red;
		display: flex;
		align-items: center;
		justify-content: center;
		@include mobile {
			height: 50vh;
		}
		.next--wrapper {
			position: relative;
			width: 60%;
			height: auto;
			@include mobile {
				width: 100%;
				height: 100%;
			}
			a {
				position: relative;
				display: block;
				@include mobile {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
				}
				.title {
					position: relative;
					display: flex;
					justify-content: center;
					flex-direction: column;
					@include mobile {
						width: 80%;
						z-index: 6;
					}
					h3 {
						font-family: 'Suisse Int';
						font-weight: 500;
						color: $white;
						font-size: 12vw;
						line-height: 12vw;
						text-transform: uppercase;
						margin: 0;
						&:first-of-type {
							align-self: flex-start;
						}
						&:last-of-type {
							align-self: flex-end;
						}
						@include mobile {
							font-size: 15vw;
							line-height: 15vw;
						}
					}
				}
				.image {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 18vw;
					height: auto;
					transform: translate(-50%, -50%);
					@include mobile {
						width: 50vw;
						z-index: 5;
					}
					img {
						width: 100%;
						height: auto;
						display: block;
					}
				}
			}
		}
	}
}
</style>